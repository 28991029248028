import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ProductCard from "../components/product-card"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const product = data.markdownRemark // data.markdownRemark holds your post data

  return (
    <Layout>
      <ProductCard key={product.id} product={product} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      id
      excerpt(pruneLength: 250)
      fields {
        slug
      }
      frontmatter {
        price
        productType
        shortDescription
        sku
        tags
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 80) {
              src
            }
          }
        }
        title
        variant1Name
        variant1Options
      }
      html
      rawMarkdownBody
    }
  }
`
